<template>
  <div class="d-flex">
    <p-icon role="button" :name="isObscured ? 'bi-eye-fill' : 'bi-eye-slash-fill'" size="20px" class="text-primary mr-1" @click.native="isObscured = !isObscured" />
    <div>
      <span v-if="isObscured" class="password">{{ obscuredText }}</span>
      <span v-else classs="password">{{ password }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    password: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isObscured: true,
    }
  },
  computed: {
    obscuredText() {
      return this.password.replace(/./g, '*')
    },
  },

}
</script>

<style lang="scss" scoped>
.password {
  width: 200px;
}
</style>
